.root-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-container {
  flex: 1 0 auto;
}

#toggle {
  margin-left: auto;
  height: 1.5rem;
  width: 1.5rem;
}

#toggle div {
  width: 100%;
  height: 3px;
  background: #54698d;
  margin: 0 auto 5px auto;
  backface-visibility: hidden;
}

#toggle.on .one {
  transform: rotate(45deg) translate(8px, 5px);
}

#toggle.on .two {
  opacity: 0;
}

#toggle.on .three {
  transform: rotate(-45deg) translate(7px, -3px);
}
