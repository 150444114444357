@import '../styles/design-tokens';

.wrapper {
  padding: 1rem 1.25rem 2rem 1.25rem;

  @media (min-width: 768px) {
    padding: 1rem 1rem 1rem 1rem;
  }
}
.header {
  font-size: $font-size-5;
  color: $color-background-inverse-light;
}
.value {
  color: $color-background-alt-inverse;
  font-size: $font-size-4;
}
.label {
  color: #54698d;
  font-size: $font-size-2;
}
.item-tag {
  :not(:last-of-type)::after {
    content: ', ';
  }
}
