@import '../../../styles/_main.scss';

.error {
  &--main-style {
    text-align: center;
    color: $header-title-color;
    background-color: white;
    min-height: 550px;
    margin: 0.75rem;
    padding-bottom: 1rem;
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px 0px;
    height: 100%;
    p {
      margin-top: 2rem;
    }
    @media screen and (max-width: 48rem) {
      br {
        display: none;
      }
    }
  }
  &--center-styling {
    min-height: 10rem;
    min-width: 10rem;
  }
  &--heading-style {
    font-size: 8rem;
    font-family: SalesforceSans-Thin;
    letter-spacing: -0.125rem;
    opacity: 0.2;
    line-height: 7rem;
  }
  &--link-style {
    color: #0c8eff;
    cursor: pointer;
  }
}
a.error--link-style:hover {
  color: $header-title-color;
  opacity: 0.5;
}
