@import '../styles/design-tokens';

.section-title {
  background-color: $color-background;
  text-transform: uppercase;
  height: 2.5rem;
  padding: 0 $spacing-medium;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_left {
    font-size: $font-size-2;
  }

  &_right {
    font-size: $font-size-1;
    color: $color-text-weak;
  }
}

.section-content {
  padding: $spacing-medium;
}
