.slds-spinner--large {
  position: absolute;
  top: 40%;
  left: 50%;
}
.container-trust {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left:2px;
}
.slds-popover{
  line-height: 1.25rem;
}
.list-item {
  max-width: 178px;
  max-height: 45px;
}
.list-anchor {
  max-width: 178px;
  max-height: 45px;
}
.container-trust:hover {
  border-radius: 4px;
  background-color: #f4f6f9;
}
#dropdown-button:focus{
  box-shadow: none;
  border:0;
  border-radius: 0;
}
.border-box {
  border-radius: 4px;
  box-shadow: 0 0 2px 1px rgba(21, 137, 238, 0.5);
  background-color: #ffffff;
  border: solid 1px #d8dde6;
}
.status-style{
  background-color: none;
}
#toggle{
  padding-top:2px;
}
.general-message-status-green {
  color: #4fc279;
}
.maintenance-status-canceled {
  color: #A86403;
}
/* Application Defaults */
$color-text-default: #16325C;
$color-text-light: rgba(22,50,92, 0.5);
$color-border-info: #54698D;
$color-border-info-light: rgba(84,105,141, 0.5);
$color-background: #F4F6F9;
$color-border: #D8DDE6;

/* Navbar background color */
$navbar-bg-color-dark: #0070D2;
$navbar-bg-color-light: #0C8EFF;
/* Navbar tabs color */
$navbar-tab-color-hover: rgba(0, 57, 107, 0.2);
$navbar-tab-color-active: rgba(0, 57, 107, 0.7);
/* Navbar font color */
$navbar-font-color: #FFFFFF;
/* Button colors */
$primary-button-bg-color: #0070D2;
/* Wizard bullets */
$wizard-bullet-active-fill: #0070D2;
$wizard-bullet-not-active: #F4F6F9;
/* Table */
$table-selected-row-border: #95B3FC;
$table-selected-row-bg: #ECF6FB;

/* Instance details page */
/* Instance grid */
$instance-square-border-color: #DCE1E9;
$instance-square-label-color: #54698D;
$instance-square-hover-gb-color: #F4F6F9;
$instance-healthy-bg-color: #4FC279;
$instance-minor-bg-color: #FF9A3C;
$instance-major-bg-color: #C23934;
$instance-maintenance-bg-color: #7F8DE1;
$instance-maintenance-title: $color-text-default;

/*Search */
$search-bar-color: #16325c;

/*Modal*/
$modal-status-ongoing:#188Df3;
$modal-status-resolved:#4fc279;
$modal-border:#E2E5EA;
$modal-title-font:#54698D;
$modal-title-background-color: $color-background;
$modal-timeline-color:#D8DDE6;

$minor-color: #FF9A3C;
$minor-color-hover: #F1790A;

$major-color: #D4504C;
$major-color-hover: #C23934;

$maintenance-color: #7F8DE1;
$maintenance-color-hover: #6C6CCE;

$informational-color: #005FB2;
$informational-color-hover: #00396B;

$healthy-color: #4FC279;

/* Application Defaults */
$color-text-default: #16325C;
$color-text-light: rgba(22,50,92, 0.5);
$color-border-info: #54698D;
$color-border-info-light: rgba(84,105,141, 0.5);
$color-background: #F4F6F9;
$color-border: #D8DDE6;

/* Navbar background color */
$navbar-bg-color: #4F6A92;
$navbar-font-color: #FFFFFF;
$navbar-font-color-light: rgba(255, 255, 255, 0.5);

/* Tabs */
$tabs-header-background: $color-background;
$tabs-header-border-color: $color-border;
$tabs-header-font-color:#4E95DD;
$tabs-header-space-color:#D9DFEA;

/* Header */
$header-title-color: $color-text-default;
$header-title-hover-color: #1589EE;

/*Footer*/
$footer-text-color: #999999;
$footer-background-color: #F6F6F6;

/* Instance grid */
$instance-grid-header-font-color: #9FAAB5;
$instance-square-border-color: #DCE1E9;
$instance-square-label-color: #54698D;
$instance-square-hover-gb-color: $color-background;
$instance-healthy-bg-color: #4FC279;
$instance-minor-bg-color: #FF9A3C;
$instance-major-bg-color: #C23934;
$instance-maintenance-bg-color: #7F8DE1;

/* Details */
$details-grid-background-color: #F7F9F9;
$details-grid-background-shadow-color: rgba(0,0,0,.07);

/* Instance grid on mobile */
$instance-text-tab-label-active: $color-text-default;
$instance-text-tab-label-inactive: #9FAAB5;

/*Maintenance browser overall */
$maintenance-calendar-button-active: $color-text-default;
$maintenance-calendar-button-inactive: rgba(22,50,92,.5);
$maintenance-calendar-button-active-background: #FFFFFF;
$maintenance-calendar-button-inactive-background: $color-background;
$maintenance-calendar-button-background-border: $color-border;
$maintenance-list-button-border: #ccc;
$maintenance-calendar-today: rgb(218, 221, 226);

/*Panel*/
$panel-card-border-color: #D8DDE6;
$panel-chevron-hover-color: #005fb2;

/*General Message*/
$general-message-link-inactive: #0070d2;
$general-message-link-active: #005fb2;
$general-message-title: $color-text-default;
$general-message-body: $color-text-default;

/*Region-Picker*/
$language-picker-button-color: #0070d2;

/*My Domain*/
$link-hover-color: #00396b;

/*Status Line*/
$status-line-affects-all-color: #2b456d;

/*Timeruler*/
$timeruler-label-color: #16235c;

/* Instance popover */
$instance-popover-timestamp-font-color: #54698D;
$instance-popover-description-font-color: $color-text-default;

/* Instance details page */
$instance-details-header-arrow: #9FAAB5;
$instance-details-bg-color: $color-background;
$overall-label-bg-color: #061c3f;
$overal-status-bg-color: #54698d;
$status-line-divider-color: #D8DDE6;

/*Modal*/
$modal-status-ongoing:#188Df3;
$modal-status-resolved:#4fc279;
$modal-border:#E2E5EA;
$modal-title-font:#54698D;
$modal-title-background-color:$color-background;
$modal-timeline-color:#D8DDE6;

$minor-color: #FF9A3C;
$minor-color-hover: #F1790A;

$major-color: #D4504C;
$major-color-hover: #C23934;

$maintenance-color: #7F8DE1;
$maintenance-color-hover: #6C6CCE;

$informational-color: #005FB2;
$informational-color-hover: #00396B;

$healthy-color: #4FC279;

/*Homepage*/
$banner-color:#00A1E0;



