@import '../styles/design-tokens';
@import '../styles/colors';

.mobile-menu {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow: auto;

  &-item {
    font-size: $font-size-6;
    height: 4rem;

    a {
      color: inherit;
      width: 100%;
      &:focus {
        text-decoration: none;
      }
    }

    &:hover,
    &:focus {
      background-color: rgba(21, 137, 238, 0.1);
      text-decoration: none;
      border-left: 0.125rem solid $color-text-link;
    }
  }
}

.expand-menu {
  background-color: $menu-background;

  &-header {
    &:hover,
    &:focus {
      background: none;
      border-left: none;
    }
  }
}

.close-section {
  height: 3rem;
}

.user-section {
  background-color: $page-header-background;

  label {
    font-size: $font-size-4;
  }
}
