.tabs-wrapper{
    border-radius: .25rem;
}

.tabs-wrapper ul {
    padding: 0 1rem;
}

.tabs-wrapper ul li {
    padding: 0 1rem;
}