@import '../../../styles/_main.scss';

.slds-grid.slds-nowrap.slds-grid--align-spread.slds-medium-grid--align-start {
  justify-content: start;
}

.slds-col.slds-medium-show.subscription-grid-cell {
  font-size: 12px;
}

// Formatting the inputs in Desktop mode
@media (min-width: 768px) {

.slds-form-element.slds-medium-p-top--reset {
    padding-top: 0rem;
  }

  .slds-form-element.slds-medium-p-left--medium {
    padding-left: 1rem;
  }
}

.subscription-grid {

  .slds-grid {
    align-items: strech;
  }

  margin: 0rem 0 1.5rem 0;
  & span {
    vertical-align: middle;
  }

  .slds-medium-hide--inline-block {
    display: inline-block;
  }
  .slds-medium-hide--inline {
    display: inline;
  }

  &-header {
    background-color: $color-background;
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    padding: 0.5rem 0.5rem 0.5rem 1rem;

    &__all {
      border-top: 0;
      background-color: white;
      border-bottom: 0;
    }
  }

  &-cell {
    padding: 0.5rem;
  }

  // Formatting in Desktop mode
  @media (min-width: 768px) {
    margin: 1.5rem;
    // surrond with border
    border: 1px solid $color-border;

    .slds-medium-grid--align-space {
      justify-content: space-around;
    }

    .slds-medium-hide,
    .slds-medium-hide--inline-block,
    .slds-medium-hide--inline
    {
      display: none;
    }

    &-cell {
      text-align: center;
    }

    &-header {
      border-right: 1px solid $color-border;
      border-top: 0;
      border-bottom: 0;
      &__all {
        background-color: $color-background;
      }
    }

    &-row:not(:last-child) {
      border-bottom: 1px solid $color-border;
    }
  }
}
