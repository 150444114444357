@import '../../../../styles/_main.scss';


.status-header {
  &--breadcrumb {
    text-transform: uppercase;
  }

  .subscribe-button {
    // display: block;
    font-size: .8125rem;
    padding: .25rem;

    height: 2rem;

    border: 1px solid #d8dde6;
    background-color: #fff;


    border-radius: .25rem;

    text-transform: uppercase;
    color:$color-text-default;
    text-align: left;
    vertical-align: middle;
    text-decoration: none;

    &:hover{
      text-decoration: none;
    }

    &--email {
      .slds-icon {
        margin-bottom: .25rem;
        line-height: 1;
        height: 1rem;
        width: 1rem;
      }
    }
  }
}

.button-mydomain {
  font-size: 0.875rem;
  color: $timeruler-label-color;
}
