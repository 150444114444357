@import '../styles/design-tokens';

@import '../styles/colors';

.product-side-nav {
  position: fixed;
  top: 3rem;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: $z-index-docked;
  padding: $spacing-medium 0;
  width: 15rem;
  background-color: $color-background-backdrop-tint;
  font-size: $font-size-4;
  font-weight: $font-weight-regular;

  &-item {
    padding: $spacing-small;
    border-left: 0.25rem solid transparent;
    display: flex;
    align-items: center;
    color: $nav-link-text;

    &:hover,
    &:focus {
      background-color: $nav-background;
      text-decoration: none;
    }

    &_active {
      background-color: $nav-background;
      font-weight: $font-weight-bold;
      color: $color-text-link;
      border-left: 0.25rem solid $color-text-link;
    }
  }
}

.product-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.salesforce-services-tooltip {
  width: $size-x-small;
}
