@import '../../../styles/_main.scss';

.slds-col.timeline-row-label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
}

.timeline {
  padding-top: 4rem;
  position: relative;
}

.timeline-marker {
  &:nth-child(2n) {
    display: none;

    @media only screen and (min-width: 30em) {
      display: block;
    }
  }

  position: absolute;
  top: 2rem;
  bottom: 0;
  width: 0;
  z-index: 51;
  border-right: 1px dashed #d8dde6;

  &--now {
    border-right-color: #1589ee;
    // position: relative;
    &:before {
      // top: 0;
      // left: 0;
      position: absolute;
      transform: translate(-0.2rem, -0.25rem);

      content: '';
      height: 0.5rem;
      width: 0.5rem;
      background: #1589ee;
      border-radius: 50%;
    }
  }

  &-label {
    display: inline-block;
    vertical-align: middle;

    font-size: 0.75rem;
    line-height: 0.7rem;
    padding: 0.4rem;
    transform: translate(-50%, -50%);

    background-color: #f4f6f9;
    border: 1px solid #54698d;
    border-radius: 0.25rem;

    .timeline-marker--now > & {
      color: #1689ee;
      border: 0;
      background: #fdfdfd;
      text-transform: uppercase;
    }

    .timeline-marker--selected-date > & {
      color: #1689ee;
      border-color: #1689ee;
    }
  }
  &.timeline-marker--selected-date {
    border-right: 1px dashed #1689ee;
  }
}

.timeline-markers {
  position: absolute;
  top: 0;
  left: 0;
  right: 8rem;
  height: 100%;
  z-index: 0;
  // width: 100%;

  .timeline--now & {
    right: 10rem;
  }
}

.timeline-buttons {
  width: 100%;
  margin-top: -48px;
  display: flex;
}

.timeline-buttons button {
  z-index: 2;
  background: white;
}

#left-arrow-button {
  margin-left: 0.75rem;
}

#right-arrow-button {
  margin-left: auto;
  margin-right: 0.75rem;
}

.timeline-controller {
  background-color: #f4f6f9;
  border-bottom: 1px solid #d8dde6;

  // split line in the middle
  > :first-child {
    border-right: 1px solid #d8dde6;
  }

  // overrides default color so all buttons are white
  .slds-button {
    background-color: #fff;
    color: #0070d2;

    &[disabled] {
      color: #d8dde6;
    }
  }

  .slds-button.slds-button--brand {
    color: white;

    &[disabled] {
      border-color: #d8dde6;
      background-color: #ffffff;
      color: #d8dde6;
    }
  }

  &-range-label {
    line-height: 2rem;
    white-space: nowrap;

    display: none;
    @media (min-width: 480px) and (max-width: 767px) {
      display: block;
    }

    @media (min-width: 1024px) {
      display: block;
    }
  }

  #calendarIcon {
    &.slds-input__icon.slds-icon-text-default {
      right: 0.5rem;
    }
    &.datepicker-active {
      fill: #0070d2;
    }
  }

  .datepicker-input {
    width: 7.5rem;
    padding-right: 0px;
    font-size: 0.8125rem;
    color: #0070d2;
    cursor: pointer;
    ::-webkit-input-placeholder,
    ::placeholder {
      color: #16325b;
      font-size: 0.8125rem;
    }
  }
  .timeline-btn--now {
    margin-right: 0.75rem;
    border-radius: 0.25rem;
    background-color: #0070d2;
  }
  .timeline-datepicker {
    margin-right: 0.75rem;
  }
  .slds-datepicker td.slds-is-selected:not(.slds-disabled-text) > .slds-day {
    background: #0070d2;
  }
  .slds-datepicker td.slds-is-today > .slds-day {
    background: #ecebea;
  }
  abbr[title] {
    text-decoration: none;
  }
}

.slds-button-group .timeline-button {
  width: 4rem;
  color: #0070d2;
  &.timeline-button-selected {
    background-color: #0070d2;
    color: #fff;
  }
}

.timeline-row {
  &-label {
    flex: 0 8rem;
    padding-left: 1rem;
    color: #061c3f;
    border-top: #d8dde6 solid 0.1rem;
    border-left: #d8dde6 solid 0.1rem;
  }

  &-body {
    border-top: #d8dde6 solid 0.1rem;
  }

  // wraps the timeline contents
  // adds margin when now
  &-body-wrapper {
    position: relative;
    height: 5rem;
    .timeline--now & {
      margin-right: 2rem;
    }
  }

  // Health green line backgroun
  &-healthy {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 0.375rem;
    background-color: $healthy-color;

    z-index: 52;
  }

  &-healthy-icon {
    background-color: #ffffff;
    fill: $healthy-color;
    border: $healthy-color solid 0.125rem;
    position: absolute;
    right: 0;
    padding: 0.25rem;
    transform: translate(50%, -50%);
    top: 50%;
    line-height: 0rem;

    svg {
      fill: $healthy-color;
      width: 0.8rem;
      height: 0.8rem;
    }

    z-index: 53;
  }

  &-impact {
    position: absolute;
    height: 0.625rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 54;
    cursor: pointer;

    // as space after each impact
    &--padded:after {
      content: '';
      // background-color: white;
      width: 0.125rem;
      position: absolute;
      left: 0;
      height: 0.625rem;
      top: 50%;
      transform: translateY(-50%);
      border-left: 0.125rem solid white;
      // background-color: transparent;
    }

    // add height to tails
    &--end:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 1rem;
      border-left: 0.125rem solid white;
    }

    // Add colors to bars and impact tails
    &--minor {
      background-color: $minor-color;

      .timeline-row-item:hover & {
        background-color: $minor-color-hover;
      }

      &:after {
        border-right: 0.125rem solid $minor-color;
      }
    }

    &--major {
      background-color: $major-color;

      .timeline-row-item:hover & {
        background-color: $major-color-hover;
      }

      &:after {
        border-right: 0.125rem solid $major-color;
      }
    }

    &--maintenance {
      background-color: $maintenance-color;

      .timeline-row-item:hover & {
        background-color: $maintenance-color-hover;
      }

      &:after {
        border-right: 0.125rem solid $maintenance-color;
      }
    }

    &--informational {
      background-color: $informational-color;

      .timeline-row-item:hover & {
        background-color: $informational-color-hover;
      }

      &:after {
        border-right: 0.125rem solid $informational-color;
      }
    }

    &-icon {
      background-color: inherit;
      position: absolute;
      border-radius: 50%;
      padding: 0.5rem;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      transform: translate(50%, -50%);
      top: 50%;
      line-height: 1rem;
      cursor: pointer;

      svg {
        width: 1rem;
        height: 1rem;
      }

      // if no
      &--offset {
        transform: translate(0, -50%);
      }

      &--minor {
        background-color: $minor-color;
        .timeline-row-item:hover & {
          background-color: $minor-color-hover;
        }
      }

      &--major {
        background-color: $major-color;
        .timeline-row-item:hover & {
          background-color: $major-color-hover;
        }
      }

      &--maintenance {
        background-color: $maintenance-color;
        .timeline-row-item:hover & {
          background-color: $maintenance-color-hover;
        }
      }

      &--informational {
        background-color: $informational-color;
        .timeline-row-item:hover & {
          background-color: $informational-color-hover;
        }
      }

      z-index: 55;
    }
  }
}
