@import '../styles/design-tokens';

@media (max-width: 1023px) {
  .global-search-wrapper {
    width: 100%;
  }
}
@media (max-width: $mq-small) {
  .slds-global-header__item_search {
    min-width: $size-medium;
    font-size: $font-size-4;
  }
}
@media (max-width: 360px) {
  .slds-global-header__item_search {
    min-width: 17rem;
    font-size: $font-size-2;
  }
}
