.global-footer {
  flex-shrink: 0;
  padding: 1rem;
}

.global-footer a {
  color: #16325c;
}

.privacy-icon {
  padding-right: 2px;
}
