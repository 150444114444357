@import './styles/design-tokens';

body {
  height: 100%;
}

html {
  height: 100%;
}

button {
  outline: none;
  &:focus {
    box-shadow: none !important;
  }
}
