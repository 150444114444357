@import '../styles/design-tokens';
@import '../styles/colors';

.product-header {
  background-color: $page-header-background;
  margin-bottom: $spacing-small;
  &-icon {
    background-color: white;
  }
}
